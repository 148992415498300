import './App.css';
import { useEffect, useRef } from 'react';
import p5 from 'p5';

function sketch(p) {
    // p is a reference to the p5 instance this sketch is attached to
    let wWidth = window.innerWidth
    let wHeight = window.innerHeight

    let points = [];
    const mult = 0.004;

    const onScreen = (v) => {
      return v.x >=0 && v.y<=wWidth && v.y >=0 && v.y <= wHeight;
    }

    p.setup = function() {
      
        p.createCanvas(wWidth, wHeight);
        p.background(0);
        
        const density = 15;
        const space = p.width / density;
        
        //Create the vector
        for(let x=0; x < p.width; x+=space){
          for(let y=0; y < p.width; y+= space){
            let poi = p.createVector(x,y);
            points.push(poi)
          }
        }
  
      
    }

    p.draw = function() {
      p.noStroke();
      p.fill(255);
      
      for(let i = 0; i< points.length; i++){
        let angle = p.map(p.noise(points[i].x*mult, points[i].y*mult), 0,1,0,720);
        
        points[i].add(p.createVector(p.cos(angle), p.sin(angle)));
        p.ellipse(points[i].x, points[i].y, 1);
      }
    }
}

function App() {
    // create a reference to the container in which the p5 instance should place the canvas
    const p5ContainerRef = useRef();

    useEffect(() => {
        // On component creation, instantiate a p5 object with the sketch and container reference 
        const p5Instance = new p5(sketch, p5ContainerRef.current);

        // On component destruction, delete the p5 instance
        return () => {
            p5Instance.remove();
        }
    }, []);

    return (
        <div className="App" ref={p5ContainerRef} />
    );
}

export default App;
